
import { Vue, Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import ListPage from '@/components/shared/ListPage';
import { RelatorioService } from '@/core/services/financeiro';
import { Banco } from '@/core/models/financeiro';
import { EmpreendimentoService } from '@/core/services/cadastros';
import { EmpresaService } from '@/core/services/compras/EmpresaService';
import { TratarErroApi } from '@/assets/scripts/helper';
import { formatDate } from '@/assets/scripts/helper';

@Component
export default class PainelReceita extends mixins(Vue, ListPage) {
    relatorio = new RelatorioReceita();
    tab = null;

    isMobile: any = false;

    isLoading: boolean = true;

    NomeDataInicial = "Data Inicial";
    NomeDataFinal = "Data Final";
    dateInicial: string | null = "";
    dateFinal: string | null = "";
    currentYear = new Date().getFullYear();
    currentMonth = new Date().getMonth();
    firstDayOfNextMonth = new Date(this.currentYear, this.currentMonth + 1, 1);
    lastDayOfCurrentMonth2 = new Date(this.firstDayOfNextMonth.getTime() - 1);
    lastDayOfCurrentMonth = new Date(this.firstDayOfNextMonth.getTime() - 1).getDate();
    formattedDateInicial: string | null = formatDate(new Date(this.currentYear, this.currentMonth, 1), 'yyyy-MM-dd');
    formattedDateFinal: string | null = formatDate(this.lastDayOfCurrentMonth2, 'yyyy-MM-dd');
    menuInicial: any = false;
    menuFinal: any = false;
 
    empresas: any = [];
    empreendimentos: any = [];

    service = new RelatorioService();   
    barChartData = {}
    pizzaChartData= {}

    filter: {
        empresasIds: any;
        empreendimentosIds: any;
        dataInicial: string | null;
        dataFinal: string | null;
    } = {
            empresasIds: 0,
            empreendimentosIds: 0,
            dataInicial: `${this.currentYear}-${String(this.currentMonth + 1).padStart(2, '0')}-01`,
            dataFinal: `${this.currentYear}-${String(this.currentMonth + 1).padStart(2, '0')}-${String(this.lastDayOfCurrentMonth).padStart(2, '0')}`
        };

    breadCrumbLinks: any[] = [
        {
            text: 'Home',
            href: '/financeiro/painel/kpi', 
        },
        {
            text: 'Painel de Receita',
            disabled: true,
            href: '/financeiro/painel/receita'
        }]
    
 
    tipoReceitas:any = []

  
    headersTipoReceita: any[] = [
        { text: '', align: 'center', value: 'name' },
        { text: 'VALOR RECEBIDO', align: 'center', value: "valorRecebido" },
        { text: 'PARCELAS PAGAS', align: 'center', value: "parcelasPagas" },
        { text: 'VALOR A RECEBER', align: 'center', value: "valorReceber" },
        { text: 'PARCELAS A RECEBER', align: 'center', value: "parcelasDevidas" }
    ];

    loading: boolean = false;
    dialogCadastro: boolean = false;

    titulo: string = 'Painel de receita';
    subTitulo: string = 'Receitas';

    options: any = {
        itemsPerPage: 15
    };

    headers: any[] = [
        { text: '', value: 'actions', sortable: false },
        { text: 'Nome', value: 'nome' }
    ];

    handleDateSelected({ date, type }: { date: string; type: string }) {
    if (type === 'initial') {
        this.filter.dataInicial = date;
    } else if (type === 'final') {
        this.filter.dataFinal = date;
    }
    this.GetKpiReceita();
    }

    async GetKpiReceita(){
        this.isLoading = true
  
    this.filter.empreendimentosIds = this.filter.empreendimentosIds ? [...this.filter.empreendimentosIds] : [],
    this.filter.empresasIds = this.filter.empresasIds ? [...this.filter.empresasIds] : []

    this.barChartData = {}

    await this.service.ObterRelatorioPainelReceita(this.filter.empreendimentosIds.join(','), this.filter.empresasIds.join(','), this.filter.dataInicial, this.filter.dataFinal).then(
      (res) => {

         if (!res || !res.data) {
      this.isLoading = false;

      return; // Sai da função
    }
        this.relatorio.primeiroTotalParcelas = res.data.primeiroTotalParcela ?? 0;
        this.relatorio.tiposRecebidosModel = res.data.tiposRecebidosModel;
        this.relatorio.tipoParcelasPagas = res.data.tipoParcelasPagas
        this.relatorio.listaParcelaPagarDia = res.data.listaParcelaPagarDia
        this.relatorio.MesAtual = res.data.mesAtual
        this.checkIsMobile();
        this.setChart();
        this.barChartData = {
        labels: [...this.relatorio.listaParcelaPagarDia.map((item) => [item.grupoDias])],
        datasets: [ 
        {

            label: 'A Receber', 
            backgroundColor: 'rgba(246, 99, 30, 0.5)',
             borderColor: [
                'rgba(246, 99, 30, 1)',
            ],
            borderWidth: 2,
            borderRadius: 5,
            data: this.relatorio.listaParcelaPagarDia.map((item)=>[item.receber]),
            barPercentage: 1
        },
        {
        label: 'Recebido', 
        backgroundColor: 'rgba(76, 175, 80, 0.5)',
        borderColor: [
            'rgba(76, 175, 80, 1)',
        ],
        borderWidth: 2,
        borderRadius: 5,
        data: this.relatorio.listaParcelaPagarDia.map((item)=>[item.recebido]),
        barPercentage: 1
        },
       ]
       };
       const labels = ['Financiamento', 'Entrada', 'Serviços','Intermediária', 'Aditamento'];
       const quantidade = [
       this.relatorio.tipoParcelasPagas.financiamento.totalRecebido + this.relatorio.tipoParcelasPagas.financiamento.totalReceber,
       this.relatorio.tipoParcelasPagas.entrada.totalRecebido + this.relatorio.tipoParcelasPagas.entrada.totalReceber,
       this.relatorio.tipoParcelasPagas.serviços.totalRecebido + this.relatorio.tipoParcelasPagas.serviços.totalReceber,
       this.relatorio.tipoParcelasPagas.intermediaria.totalRecebido + this.relatorio.tipoParcelasPagas.intermediaria.totalReceber,
       this.relatorio.tipoParcelasPagas.aditamento.totalRecebido + this.relatorio.tipoParcelasPagas.aditamento.totalReceber
        ];
        const backgroundColors = [
        'rgba(252, 207, 185, 0.7)',  
        'rgba(251, 183, 152, 0.7)',  
        'rgba(224, 90, 27, 0.7)',   
        'rgba(175, 70, 21, 0.7)',    
        ];

        const borderColors = [
        'rgba(252, 207, 185, 1)',  
        'rgba(251, 183, 152, 1)',  
        'rgba(224, 90, 27, 1)',   
        'rgba(175, 70, 21, 1)',   
        ];

        const filteredData = quantidade
        .map((q, index) => ({ value: q, bgColor: backgroundColors[index], borderColor: borderColors[index], label: labels[index] }))
        .filter(item => item.value > 0);
       this.pizzaChartData = {
                    labels: filteredData.map(item => item.label),
                    datasets: 
                    [
                        {
                            label: 'Valor',
                            backgroundColor: filteredData.map(item => item.bgColor),
                            borderColor: filteredData.map(item => item.borderColor),
                            data: filteredData.map(item => item.value),
                     }],
                }
      },
      (err) => {
        TratarErroApi(err)
        this.isLoading = false
      }
    ) 
    
      this.isLoading = false;
    
  }
  
  setChart(){
    this.tipoReceitas = [
        { name: this.relatorio.tipoParcelasPagas.financiamento.nome, 
                valorRecebido: Number(this.relatorio.tipoParcelasPagas.financiamento.totalRecebido), 
                parcelasPagas: this.relatorio.tipoParcelasPagas.financiamento.quantidadeParcela,
                valorReceber: this.relatorio.tipoParcelasPagas.financiamento.totalReceber ?? 0, 
                parcelasDevidas: this.relatorio.tipoParcelasPagas.financiamento.quantidadeParcelaReceber ?? 0 },
        { name: this.relatorio.tipoParcelasPagas.entrada.nome, 
                valorRecebido: this.relatorio.tipoParcelasPagas.entrada.totalRecebido ?? 0, 
                parcelasPagas: this.relatorio.tipoParcelasPagas.entrada.quantidadeParcela,
                valorReceber: this.relatorio.tipoParcelasPagas.entrada.totalReceber ?? 0, 
                parcelasDevidas: this.relatorio.tipoParcelasPagas.entrada.quantidadeParcelaReceber ?? 0 },
        { name: this.relatorio.tipoParcelasPagas.serviços.nome, 
                valorRecebido: this.relatorio.tipoParcelasPagas.serviços.totalRecebido ?? 0, 
                parcelasPagas: this.relatorio.tipoParcelasPagas.serviços.quantidadeParcela,
                valorReceber: this.relatorio.tipoParcelasPagas.serviços.totalReceber ?? 0, 
                parcelasDevidas: this.relatorio.tipoParcelasPagas.serviços.quantidadeParcelaReceber  ?? 0},
        { name: this.relatorio.tipoParcelasPagas.intermediaria.nome, 
                valorRecebido: this.relatorio.tipoParcelasPagas.intermediaria.totalRecebido ?? 0, 
                parcelasPagas: this.relatorio.tipoParcelasPagas.intermediaria.quantidadeParcela,
                valorReceber: this.relatorio.tipoParcelasPagas.intermediaria.totalReceber ?? 0, 
                parcelasDevidas: this.relatorio.tipoParcelasPagas.intermediaria.quantidadeParcelaReceber ?? 0 },
        { name: this.relatorio.tipoParcelasPagas.aditamento.nome, 
                valorRecebido: this.relatorio.tipoParcelasPagas.aditamento.totalRecebido ?? 0, 
                parcelasPagas: this.relatorio.tipoParcelasPagas.aditamento.quantidadeParcela,
                valorReceber: this.relatorio.tipoParcelasPagas.aditamento.totalReceber ?? 0, 
                parcelasDevidas: this.relatorio.tipoParcelasPagas.aditamento.quantidadeParcelaReceber ?? 0 },
    ];      
    
  }
    get selecionarTodosEmpreendimentos() {
        return this.filter.empreendimentosIds.length === this.empreendimentos.length
    }

    get SelecionarAlgunsEmpreendimentos() {
        return this.filter.empreendimentosIds.length > 0 && !this.selecionarTodosEmpreendimentos
    }

    get iconEmpreendimento() {
        if (this.selecionarTodosEmpreendimentos) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpreendimentos) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpreendimento() {
        if (this.selecionarTodosEmpreendimentos) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpreendimentos) return 'Selecionando...'
        return 'Selecionar todos'
    }

    SelecionarEmpreendimento() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpreendimentos) {
                this.filter.empreendimentosIds = []
            } else {
                this.filter.empreendimentosIds = this.empreendimentos.slice().map((x: any) => x.id)
            }
            this.GetKpiReceita();
        })
    }

    SelecionarEmpresa() {
        this.$nextTick(() => {
            if (this.selecionarTodosEmpresas) {
                this.filter.empresasIds = []
            } else {
                this.filter.empresasIds = this.empresas.slice().map((x: any) => x.id)
            }
            this.GetKpiReceita();
        })
    }

    get iconEmpresa() {
        if (this.selecionarTodosEmpresas) return 'mdi-close-box'
        if (this.SelecionarAlgunsEmpresas) return 'mdi-minus-box'
        return 'mdi-checkbox-blank-outline'
    }

    get textEmpresa() {
        if (this.selecionarTodosEmpresas) return 'Desmarcar'
        if (this.SelecionarAlgunsEmpresas) return 'Selecionando...'
        return 'Selecionar todos'
    }

    get selecionarTodosEmpresas() {
        return this.filter.empresasIds.length === this.empresas.length
    }

    get SelecionarAlgunsEmpresas() {
        return this.filter.empresasIds.length > 0 && !this.selecionarTodosEmpresas
    }

  
    checkIsMobile() {
        this.isMobile = window.innerWidth <= 768;
    }
    created() {
        window.addEventListener('resize', this.checkIsMobile);
    }
    beforeDestroy() {
       //window.removeEventListener('resize', this.checkIsMobile);
    }

    mounted() {
        new EmpreendimentoService().Listar(-1, -1, ['nome'], [], '', [], '', '', 'Id, nome', '').then(
            res => this.empreendimentos = res.data.items
        );

        new EmpresaService().Listar(-1, -1, ['nome'], [], '', [], '', '', 'Id, nome, nomeFantasia', '').then(
            res => this.empresas = res.data.items
        );
        this.checkIsMobile();
        this.GetKpiReceita();
    }
}
class RelatorioReceita {
    primeiroTotalParcelas:any ={};
    tiposRecebidosModel:TiposRecebidosModel=new TiposRecebidosModel()
    tipoParcelasPagas: TiposParcelasPagas = new TiposParcelasPagas()
    listaParcelaPagarDia: any[] = [];
    TiposAditamentos:any = {};
    MesAtual: string = "";
}
class TiposRecebidosModel{
    recebidoAdiantado:any={}
    recebidoAtrasado:any={}
    recebidoEmDia:any={}
}
class TiposParcelasPagas{
   aditamento:any = {}
   entrada:any = {}
   financiamento:any = {}
   serviços:any = {}
   intermediaria:any = {}
}


